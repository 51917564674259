"use client";
import { logger } from "@/lib/logging/logger";

import { Card } from "@/components/ui";
import { toast } from "@/components/ui/use-toast";
import { TASK_FAILED_TIME_SECONDS } from "@/lib/constants";
import { getOpeningName, getVariationRoot } from "@/lib/openings/openings";
import {
  formatGameSource,
  formatShortenPgn,
  formatString,
  isAdminUser,
  staleGame,
} from "@/lib/utils";
import { ClientUser } from "@/types/ClientUser";
import { ChessGamePreview } from "@/types/Move";
import moment from "moment";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React, { useEffect, useMemo, useState } from "react";
import { AccuracyBar } from "./AccuracyBar";
import { GameCardActions } from "./GameCardActions";
import { GamestatusPopover } from "./GamestatusPopover";
import { PgnCardActions } from "./PgnCardActions";
import { PrivacyActions } from "./PrivacyActions";
import { SafeImage } from "./SafeImage";
import { getPublicAssetPath } from "@/lib/asssetUtils";
import { useCollectionStore } from "../stores/bearStore";

import {
  addGameToCollection,
  createFavoritesCollectionWithGame,
  removeGameFromCollection,
} from "@/app/services/collection.service";

import resubmitGame from "@/lib/game/resubmitGame";
import { useLoadingModal } from "@/hooks/useLoadingModal";
import { Share2Icon } from "lucide-react";

interface GameDescriptionProps {
  game: ChessGamePreview;
  user: ClientUser | null;
  fragment?: string;
  apiFragment?: string;
  handleSelectOpening: (opening: string) => void;
  CardActions?: typeof GameCardActions | typeof PgnCardActions;
  sharedBy?: string;
}

export const GameDescription = React.forwardRef<
  React.ElementRef<typeof Card>,
  GameDescriptionProps
>(
  (
    {
      game,
      user,
      handleSelectOpening,
      fragment = "/game",
      apiFragment = "/api/game",
      CardActions,
      sharedBy,
    }: GameDescriptionProps,
    ref,
  ) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const router = useRouter();
    const { collections, addToCollections, editCollection } =
      useCollectionStore();
    const fallbackUrl = getPublicAssetPath("/assets/game_placeholder.png");
    const isFavorite = useMemo(
      () =>
        collections.some((x) => x.gamesPreview.some((y) => y.id === game.id)),
      [collections],
    );

    const loadingModal = useLoadingModal();

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const handleClickOpening = (e: any) => {
      e.preventDefault();
      handleSelectOpening(
        game.opening ? game.opening : getOpeningName(game.pgn),
      );
    };

    const handleFavorite = async () => {
      try {
        let favoriteCol = collections.find((x) => x.name === "Favorites");
        if (!favoriteCol) {
          const data = await createFavoritesCollectionWithGame(game.id);
          addToCollections(data);
          favoriteCol = data;
        }
        if (isFavorite) {
          // remove
          await removeGameFromCollection(favoriteCol.id, game.id);
          editCollection({
            collectionId: favoriteCol.id,
            collection: {
              ...favoriteCol,
              gamesPreview: favoriteCol.gamesPreview.filter(
                (g) => g.id !== game.id,
              ),
            },
          });
          toast({
            description: "Removed from favorites",
            duration: 3000,
          });
        } else {
          // add
          const updatedCollection = await addGameToCollection(
            favoriteCol.id,
            game.id,
          );
          editCollection({
            collectionId: favoriteCol.id,
            collection: {
              ...favoriteCol,
              gamesPreview: [
                ...favoriteCol.gamesPreview,
                {
                  createdAt: game.createdAt,
                  id: game.id,
                  pgn: game.pgn ?? "",
                },
              ],
            },
          });
          toast({
            description: "Added to favorites",
            duration: 3000,
          });
        }
      } catch (error) {
        console.error(error);
        toast({
          variant: "destructive",
          description: "Something went wrong.",
          duration: 3000,
        });
      }
    };

    const resubmit = async () => {
      if (!game) {
        return;
      }
      if (!game.images.original) {
        toast({
          description: "No image",
          duration: 3000,
        });
      } else {
        try {
          loadingModal.onOpen();
          const data = await resubmitGame(game);
          loadingModal.onClose();
          router.push(`/game/${data.id}`);

          toast({
            description: "Success",
            duration: 3000,
          });
        } catch (error) {
          loadingModal.onClose();
          logger.info(error);
          toast({
            variant: "destructive",
            description: "Something went wrong.",
            duration: 3000,
          });
        }
      }
    };

    const handleResubmit = () => {
      resubmit();
    };

    const canEdit = () => {
      return !!user;
    };

    const isFailedGame =
      ((game.status !== "SUCCESS" && game.status !== "COMPLETED") ||
        ((game.status === "SUCCESS" || game.status === "COMPLETED") &&
          !game.pgn)) &&
      staleGame(game.createdAt, TASK_FAILED_TIME_SECONDS);

    const getUsername = (game: ChessGamePreview) => {
      if (!game.user) return "anonymous";
      if (game.user.name) return game.user.name;
      if (game.user.email) return game.user.email;
      return "anonymous";
    };

    return (
      <Link href={`${fragment}/${game.id}`}>
        <div className="flex text-white p-4 space-x-4 rounded-xl shadow-xl bg-white">
          {!isMobile && (
            <div className="flex relative shrink-0 w-[90px] h-[90px] sm:w-[170px] sm:h-[190px] bg-white rounded-md overflow-hidden self-center">
              <SafeImage
                // src={game.imgUrl}
                src={fallbackUrl}
                fallbackUrl={fallbackUrl}
                fill
                sizes="(max-width: 640px) 50vw, 20vw"
                quality={75}
                alt="PGN photo"
                className="object-cover"
              />
              <GamestatusPopover
                absolute
                status={isFailedGame ? "FAILED" : game.status}
                handleResubmit={handleResubmit}
              />
            </div>
          )}
          <div className="flex flex-col w-full items-start justify-between">
            <div className="flex justify-between w-full mb-4 sm:mb-0 items-start overflow-hidden text-ellipsis">
              <div className="flex flex-col items-start max-w-[60%] shrink gap-1">
                <span
                  className="text-base font-bold text-slate-800 text-left pr-4 hover:underline whitespace-nowrap text-ellipsis max-w-full overflow-hidden"
                  onClick={handleClickOpening}
                >
                  {isFailedGame
                    ? "Failed Game"
                    : game.opening
                      ? getVariationRoot(game.opening)
                      : "Missing opening data..."}
                </span>
                <p
                  className="text-xs sm:text-sm text-slate-600 text-left font-light"
                  title={moment(game.createdAt).format("DD MMM, YYYY HH:mm")}
                >
                  {moment(game.createdAt).fromNow()}

                  {isAdminUser(user) && (
                    <Link href={`/games/${game.user?.id}`}>
                      {" "}
                      {` by ${getUsername(game)}`}{" "}
                    </Link>
                  )}
                </p>
                {sharedBy ? (
                  <div className="text-xs flex items-end h-max gap-2 text-slate-400 text-left">
                    <Share2Icon className="h-4 w-4 text-blue-500" />{" "}
                    <p>{`by ${sharedBy}`}</p>
                  </div>
                ) : (
                  <div className="h-4 w-4"></div>
                  // Spacer ^, neccessary since addition of sharedBy to mantain vertical centering of swords
                )}
              </div>
              {!!canEdit() && (
                <div className="flex items-center shrink-0 justify-end text-slate-950 relative">
                  {!isMobile && (
                    <PrivacyActions
                      game={game}
                      user={user}
                      fragment={"/api/game/privacy"}
                    />
                  )}
                  {CardActions && (
                    <CardActions
                      game={game}
                      user={user}
                      fragment={apiFragment}
                      isFavorite={isFavorite}
                      handleFavorite={handleFavorite}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center w-full py-2">
              <div className="flex items-center space-x-4 self-center justify-between w-full">
                <div className="flex flex-col items-center w-full shrink-1">
                  <span className="font-bold text-slate-800 text-lg sm:text-xl ml-auto">
                    {game.attachedPgn && game.attachedPgn.white
                      ? game.attachedPgn.white
                      : "White"}
                  </span>
                </div>
                <Image
                  alt="icon"
                  src="/assets/swords_icon.svg"
                  width={55}
                  height={55}
                />
                <div className="flex flex-col items-center w-full shrink-1">
                  <span className="font-bold text-slate-800 text-ls sm:text-xl mr-auto">
                    {game.attachedPgn && game.attachedPgn.black
                      ? game.attachedPgn.black
                      : "Black"}
                  </span>
                </div>
              </div>
            </div>
            <div className="h-4 w-4"></div> {/* Spacer */}
            <div className="flex items-start mt-4 sm:mt-0 justify-between w-full items-end max-w-full">
              <div className="flex flex-col items-start gap-1">
                <p className="text-xs sm:text-sm font-semibold text-slate-800 text-left max-w-full whitespace-nowrap text-ellipsis overflow-hidden pr-4">
                  {game.pgn && game.opening
                    ? formatString(game.opening, 30)
                    : "Missing opening data..."}
                </p>
                <p className="text-xs sm:text-sm text-slate-600 text-left font-light">
                  {game.pgn && formatShortenPgn(game.pgn, isMobile ? 2 : 3)}
                </p>
              </div>
              <div className="flex flex-col items-center">
                {game.gameSource !== "IMAGE" && (
                  <p className="text-gray-400 text-xs sm:text-sm font-bold text-left w-full">
                    Source: {formatGameSource(game.gameSource)}
                  </p>
                )}
                {!!game.gameScore &&
                  !!game.gameScore.ocrAccuracy &&
                  !!game.gameScore.fischerScoreAvg &&
                  game.gameSource === "IMAGE" && (
                    <>
                      <p className="font-semibold text-xs sm:text-sm text-slate-800 whitespace-nowrap">
                        Estimated Accuracy
                      </p>
                      <AccuracyBar ocrAccuracy={game.gameScore?.ocrAccuracy} />
                    </>
                  )}
              </div>
            </div>
            {isMobile && (
              <div className="flex flex-col w-full items-center mt-2 gap-1">
                <PrivacyActions
                  game={game}
                  user={user}
                  fragment={"/api/game/privacy"}
                />
                <GamestatusPopover
                  status={isFailedGame ? "FAILED" : game.status}
                  handleResubmit={handleResubmit}
                />
              </div>
            )}
          </div>
        </div>
      </Link>
    );
  },
);
